<template>
  <NuxtLink :to="localePath('/articles/'+post?.slug)" :title="post?.title"><span v-html="post?.title"></span></NuxtLink>
</template>
<script setup lang="ts">
import { type PostShort } from '~/types/Post';

const localePath = useLocalePath()
const props = defineProps({
  post: {
    type: Object as () => PostShort,
  },
});
</script>
